import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import logo from './logo.png'; // Import your logo
import AskQuestion from './AskQuestion/AskQuestion.js';
import { Spinner } from 'react-bootstrap';

function App() {
  const backendDBTypeOptions = [
    { value: 'postgres', label: 'Postgres' },
    { value: 'snowflake', label: 'Snowflake' },
  ];
  const [loading, setLoading] = useState(false);
  const [backendDBType, setBackendDBType] = useState('');
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState(null);
  const [tables, setTableNames] = useState(null);

  useEffect(() => {
    const fetchTableNames = async () => {
      if (backendDBType) {
        const host = process.env.REACT_APP_HOST || 'http://localhost:8000';
        try {
          const table_response = await fetch(`${host}/fetch_tables?backend=${backendDBType}`);
          const table_names = await table_response.json();
          setTableNames(table_names.table_names);
        } catch (error) {
          console.error('Error fetching table names:', error);
        }
      } else {
        setTableNames(null);
      }
    };

    fetchTableNames();
  }, [backendDBType]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const host = process.env.REACT_APP_HOST || 'http://localhost:8000';
    const response = await fetch(`${host}/ask?question=${encodeURIComponent(question)}&backend=${backendDBType}`);
    const data = await response.json();

    if (!response.ok || data.error) {
      const message = `An error has occurred: ${data.error || response.status}`;
      alert(message);
    } else {
      setAnswer(data.answer);
    }
    
    setLoading(false);
  };

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} alt="Logo" className="logo" />
        <form onSubmit={handleSubmit} className="space-y-4 md:space-y-0 md:space-x-4">
          <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
            <Select
              options={backendDBTypeOptions}
              onChange={(e) => setBackendDBType(e.value)}
              className="w-full mb-5"
            />

            {/* Display tables next to backendDBType */}
            {tables !== null && (
              <div className="tables">
                <ul>
                  {tables?.map((tableName, index) => (
                    <li key={index}>{tableName}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>

          <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 mt-5 items-center">
            <input
              type="text"
              placeholder="Ask your data"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              className="py-2 px-4 w-full md:w-auto text-black rounded-full border-2"
            />
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full md:w-auto w-24"
            >
              Ask
            </button>
          </div>
        </form>
        
        {loading ? (
          <Spinner animation="border" role="status" className="black-spinner">
            <span style={{ color: 'black' }}>Loading...</span>
          </Spinner>
        ) : (
          answer && <div className="answer">{answer}</div>
        )}

        <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 mt-8">
          <button
            className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
            onClick={() =>
              (window.location.href = 'https://7lpty66gt1d.typeform.com/to/wRxhkZpM')
            }
          >
            connect to your data
          </button>

          <button
            className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
            onClick={() =>
              (window.location.href = 'https://7lpty66gt1d.typeform.com/to/ZHPz9YiV')
            }
          >
            give us feedback
          </button>
        </div>
      </header>
      <Routes>
        <Route path="/ask" component={AskQuestion} />
      </Routes>
    </div>
  );
}

export default App;
