import React, { useState } from 'react';
import Select from 'react-select';

function AskQuestion() {
  const [selectedTable, setSelectedTable] = useState('');
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState(null);
  const backendDBTypeOptions = [
    { value: 'postgres', label: 'Postgres' },
    { value: 'snowflake', label: 'Snowflake' },
  ]

  const handleSubmit = async (e) => {
    // Validation and submission logic
    e.preventDefault();
    const host = process.env.REACT_APP_HOST || 'http://localhost:8000';
    // const table_name_response = await fetch(`${host}/fetch_tables?db_name=${encodeURIComponent(database)}&&backend=${backendDBType}`);
    // const response = await fetch(`${host}/ask?question=${encodeURIComponent(question)}&backend=${backendDBType}`);
    // const data = await response.json();

    // setAnswer(data.answer);
  };

  return (
    <div className="AskQuestion">
        <header className="AskQuestion-header">
            <form onSubmit={handleSubmit}>
                <Select 
                    value={selectedTable} 
                    onChange={(e) => setSelectedTable(e.target.value)}
                >
                    {/* Options for table names */}
                </Select>
                <input
                    type="text"
                    placeholder="Ask your data"
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                />
                <button type="submit">Ask</button>
            </form>
            {answer && <div className="answer">{answer}</div>}
        </header>
    </div>
  );
}

export default AskQuestion;
